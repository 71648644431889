import { extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/foundations/typography.ts
const colors = {
    brand: {
        // base
        blue: '#005078',
        red: '#e74e42',

        // app
        background: '#e2e8f0',
        blue_light: '#00a7e3',
        blue_lightest: '#EDF2F7',
        grey_lightest: '#fbfbfb',
        grey_lighter: '#ebebeb',
        grey_lighter2: '#ebebeb',
        grey_light: '#898989',
        grey: '#363636',
        grey_dark: '#232323',
        red: '#fa4a3d',
        red_light: '#F7C2BE',
        green: '#5cb85c',
        yellow: '#fad53d',
        orange: '#F76F29',

        // button
        50: '#E8F4F9',
        100: '#D9DEE9',
        200: '#B7C2DA',
        300: '#6482C0',
        400: '#4267B2',
        500: '#005078', // bg
        600: '#00334c', // hoover
        700: '#29487D',
        800: '#223B67',
        900: '#1E355B',
    },
    // takeactive: {
    //     50: '#dffeec',
    //     100: '#b9f5d0',
    //     200: '#90edb3',
    //     300: '#65e495',
    //     400: '#3cdd78',
    //     500: '#22c35e',
    //     600: '#179848',
    //     700: '#0c6c33',
    //     800: '#01421c',
    //     900: '#001803',
    // },
};

// https://github.com/tailwindlabs/tailwindcss/blob/master/stubs/defaultConfig.stub.js
const sans = [
    'system-ui',
    'ui-sans-serif',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    '"Noto Sans"',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
    '"Noto Color Emoji"',
];

const config = {
    cssVarPrefix: 'take',
    initialColorMode: 'light',
};

const Theme = extendTheme({
    components: {
        Steps,
    },
    config,
    colors,
    fonts: {
        heading: sans.join(' '),
        body: sans.join(' '),
    },
    styles: {
        global: {
            body: {
                bg: 'brand.background',
                color: 'gray.700',
            },
            h2: {
                color: 'brand.blue',
                fontWeight: 'semibold',
                fontSize: 'md',
                marginTop: 4,
                textDecoration: 'underline',
            },
            p: {
                marginTop: 4,
            },
            ul: {
                marginTop: 1,
                marginLeft: 8,
            },
            ol: {
                marginTop: '0.5rem',
                marginLeft: '1.75rem',
            },

            '.screenview': {
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            },

            '.maximumheight': {
                flex: '1 1 0%',
            },

            //   ul {
            //     @apply ml-6 mt-3 mb-3 list-inside list-disc text-base;
            //   }

            //   ol {
            //     @apply ml-6 mt-3 mb-3 list-inside list-decimal text-base;
            //   }
            // styles for the `a`
            // a: {
            //     color: 'teal.500',
            //     _hover: {
            //         textDecoration: 'underline',
            //     },
            // },
            '.rounded-full': {
                rounded: 'full',
            },
            '.rounded-md': {
                rounded: 'md',
            },
            '.fill-current': {
                fill: 'currentColor',
            },

            '.btn-header,': {
                // bg: 'brand.red',
                // ml: 4,
                // mt: 1,
                px: 3,
                py: 2,
                rounded: 'md',
                text: 'sm',
                text: 'gray.300',
                fontWeight: 600,
                // width: 'full',
                width: ['full', 'full', 'initial'],
                textAlign: ['left', 'center'],
                cursor: 'pointer',
                height: '100%',
            },
            '.btn-header.active, .btn-header_settings.active .chakra-button': {
                bg: 'gray.800',
            },
            '.btn-header&:hover, .btn-header_settings&:hover .chakra-button': {
                bg: 'blue.50',
                // bg: 'blue.200',
                // color: 'brand.red',
                color: 'brand.blue',
                textDecoration: 'underline',
                // color: 'gray.800',
            },
            // '.btn-header_settings .chakra-button': {
            //     width: ['full', 'full', 'initial'],
            //     textAlign: ['left', 'center'],
            //     cursor: 'pointer',
            // },
            '.navbar-userpicture-active .navbar-userpicture-hover': {
                border: '2px solid #A0AEC0 !important',
                // borderColor: 'gray.400',
            },
            '.navbar-userpicture-hover': {
                _hover: {
                    border: '2px solid #CBD5E0 !important',
                },
            },
            // '.btn-header_settings.active .chakra-button': {
            //     bg: 'gray.800',
            // },

            // .btn-header {
            // @apply ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-300;
            // &.active {
            //   @apply bg-gray-900;
            // }

            // &:hover {
            //   @apply bg-blue-100;
            //   color: $color-primary;
            // }
            //   }
        },
    },
});

export default Theme;
