// https://kentcdodds.com/blog/how-to-use-react-context-effectively
// https://kentcdodds.com/blog/authentication-in-react-applications
// https://github.com/vercel/next.js/blob/canary/examples/with-firebase/context/userContext.js

// https://colinhacks.com/essays/nextjs-firebase-authentication

import firebaseApp from '@/components/firebase/firebaseApp';
import {
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
} from 'firebase/auth';
import { dd } from '@/components/helper';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';

// create
const AuthContext = createContext();

// provider
function AuthProvider({ children }) {
    const router = useRouter();
    const [authData, setAuth] = useState(null);
    const [isLoadingAuth, setLoadingAuth] = useState(true); // Helpful, to update the UI accordingly.

    useEffect(() => {
        // Listen authenticated user
        const authInstance = getAuth(firebaseApp);
        const authUnsubscriber = onAuthStateChanged(
            authInstance,
            async userDatabase => {
                dd('onAuthStateChanged', userDatabase);
                try {
                    // No user is signed in.
                    // console.log('userDatabase', userDatabase);
                    if (!userDatabase) {
                        if (authData !== null) {
                            setAuth(null);
                        }
                        if (isLoadingAuth === true) {
                            setLoadingAuth(false);
                        }
                        return;
                    }

                    // is user verfied
                    if (
                        userDatabase.emailVerified === false &&
                        wasLoginViaSocialProvider(userDatabase) === false
                    ) {
                        logout(setLoadingAuth);
                        return;
                    }

                    // User is signed in.
                    let netAuthData = transformAuthData(userDatabase);
                    if (_.isEqual(authData, netAuthData) === false) {
                        setAuth(netAuthData);
                    }
                    if (isLoadingAuth === true) {
                        setLoadingAuth(false);
                    }
                } catch (error) {
                    // Most probably a connection error. Handle appropriately.
                    dd('onAuthStateChanged error', error);
                } finally {
                    dd('onAuthStateChanged finaly');
                    // setLoadingAuth(false)
                }
            },
        );

        // Unsubscribe auth listener on unmount
        return () => authUnsubscriber();
    }, [authData]);

    return (
        <AuthContext.Provider
            value={{
                auth: authData,
                isLoadingAuth,
                isAuthenticated: () => isAuthenticated(authData),
                isGuest: () => isGuest(authData),
                login: (username, password, onSuccess) =>
                    login(
                        setLoadingAuth,
                        router,
                        username,
                        password,
                        onSuccess,
                    ),
                refreshToken: onSuccess =>
                    refreshToken(authData, setAuth, setLoadingAuth, onSuccess),
                logout: () => logout(setLoadingAuth),
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

// helper
function useAuthContext() {
    const context = useContext(AuthContext);
    // if (context === undefined) {
    //     throw new Error('useAuthContext must be used within a AuthProvider')
    // }
    return context;
}

// actions
function isAuthenticated(authData) {
    return authData !== null;
}

function isGuest(authData) {
    return authData === null;
}

async function refreshToken(auth, setAuth, setLoadingAuth, onSuccess = null) {
    await setLoadingAuth(true);

    // get new token
    let newJwtToken = await getAuth(firebaseApp).currentUser.getIdToken(true);

    // set store
    // if (newJwtToken) {
    //     console.log('newJwtToken', newJwtToken)
    //     console.log('auth', auth)
    //     setAuth({
    //         ...auth,
    //         accessToken: newJwtToken,
    //     })
    //     console.log('auth2', auth)
    // }

    await setLoadingAuth(false);
    if (onSuccess !== null) {
        onSuccess();
    }
}

async function login(
    setLoadingAuth,
    router,
    username,
    password,
    onSuccess = null,
) {
    await setLoadingAuth(true);

    const authInstance = getAuth(firebaseApp);

    await signInWithEmailAndPassword(authInstance, username, password)
        .then(userCredential => {
            // Signed in
            const user = userCredential.user;

            if (
                user.emailVerified === false &&
                wasLoginViaSocialProvider(user) === false
            ) {
                throw new Error('Bitte bestätige deine E-Mail Adresse.');
            }

            dd('Login done', username);
            if (onSuccess !== null) {
                onSuccess();
            }
            //  else {
            // router.push('/dashboard')
            // }
        })
        .catch(error => {
            dd('Login error', error);
            setLoadingAuth(false);

            let errorCodes = {
                'auth/wrong-password': 'Das Passwort ist falsch.',
                'auth/user-disabled':
                    'Das Konto ist deaktiviert. Bitte wenden Sie sich an den Support Team.',
                'auth/user-not-found':
                    'Ist die E-Mail Adresse richtig eingegeben worden? Wir konnten keinen Benutzer mit dieser E-Mail Adresse finden.',
                'auth/too-many-requests':
                    'Du hast zu oft das Passwort falsch eingegeben. Probiere etwas später dich einzugloggen.',
                'auth/network-request-failed':
                    'Es existiert keine Internetverbindung. Um dich einzuloggen benötigst du eine aktive Internetverbindung.',
            };

            if (errorCodes[error.code]) {
                throw new Error(errorCodes[error.code]);
            } else if (error.code) {
                throw new Error(error.code);
            } else {
                throw new Error(error);
            }
        });
}

async function logout(setLoadingAuth) {
    await setLoadingAuth(true);

    const authInstance = getAuth(firebaseApp);

    await signOut(authInstance);
}

function wasLoginViaSocialProvider(userDatabase) {
    let allowedLoginProvider = ['facebook.com', 'apple.com'];

    let providerFounded = userDatabase.providerData.find(item =>
        allowedLoginProvider.includes(item.providerId),
    );

    return providerFounded !== undefined;
}

function transformAuthData(userDatabase) {
    return {
        uid: userDatabase.uid,
        email: userDatabase.email,
        emailVerified: userDatabase.emailVerified,
        // accessToken: userDatabase.accessToken,
        // creationTime: new Date(userDatabase.metadata.creationTime),
        // lastSignInTime: new Date(userDatabase.metadata.lastSignInTime),
    };
}

export { AuthProvider, useAuthContext };
