module.exports = {
    algolia: {
        // applicationId: 'TO2F04TXTS',
        // adminApiKey: '123456789',
        // prefix: 'firebaseSSRStarter',
    },
    // functionUrl: 'https://europe-west2-takeactive-prod.cloudfunctions.net/api',
    // storageUrl: 'https://storage.googleapis.com/takeactive-prod.appspot.com/',
    functionUrl: process.env.NEXT_PUBLIC_FIREBASE_FUNCTION_URL,
    storageUrl: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_URL,
    schema: {
        users: 'users',
        pages: 'pages',
        companies: 'companies',
        companyMembers: 'members',
        contacts: 'contacts',
        appointments: 'appointments',
        appointmentTemplates: 'appointmentTemplates',
        roles: 'roles',
        audit: 'audit',
        products: 'products',
        private: 'private',
        statistics: 'statistics',
        bills: 'bills',
    },
    membershipStatus: {
        REQUEST_STARTED: 'REQUEST_STARTED',
        MEMBER: 'MEMBER',
        ADMIN: 'ADMIN',
    },
    companyStatus: {
        OFFLINE: 'OFFLINE',
        REVIEW: 'REVIEW',
        DEMO: 'DEMO',
        ACTIVE: 'ACTIVE',
    },
    companyStatusText: {
        OFFLINE: 'Deaktiviert (Probemitgliedschaft abgelaufen)',
        REVIEW: 'In Prüfung (Suche ausgeschlossen)',
        DEMO: 'Testmitgliedschaft',
        ACTIVE: 'Aktiv',
    },
    roleSlugStatusPicker: [
        {
            label: 'Anfrage gestartet',
            value: 'REQUEST_STARTED',
        },
        {
            label: 'Mitglied',
            value: 'MEMBER',
        },
        {
            label: 'Trainer',
            value: 'TRAINER',
        },
        {
            label: 'Planung',
            value: 'PLANNING',
        },
        {
            label: 'Admin',
            value: 'ADMIN',
        },
    ],
    userRole: {
        MEMBER: 'MEMBER',
        ADMIN: 'ADMIN',
    },
    appointmentTemplatePeriod: {
        DAILY: 'DAILY',
        WEEKDAYS: 'WEEKDAYS',
        WEEKEND: 'WEEKEND',
        MONDAY: 'MONDAY',
        TUESDAY: 'TUESDAY',
        WEDNESDAY: 'WEDNESDAY',
        THURSDAY: 'THURSDAY',
        FRIDAY: 'FRIDAY',
        SATURDAY: 'SATURDAY',
        SUNDAY: 'SUNDAY',
    },
    appointmentTemplatePeriodWeekday: {
        MONDAY: 1,
        TUESDAY: 2,
        WEDNESDAY: 3,
        THURSDAY: 4,
        FRIDAY: 5,
        SATURDAY: 6,
        SUNDAY: 7,
    },
    appointmentTemplatePeriodLabel: {
        DAILY: 'Täglich',
        WEEKDAYS: 'Werktage (Mo, Di, Mi, Do, Fr)',
        WEEKEND: 'Wochenende (Sa, So)',
        MONDAY: 'Jeweils am Montag',
        TUESDAY: 'Jeweils am Dienstag',
        WEDNESDAY: 'Jeweils am Mittwoch',
        THURSDAY: 'Jeweils am Donnerstag',
        FRIDAY: 'Jeweils am Freitag',
        SATURDAY: 'Jeweils am Samstag',
        SUNDAY: 'Jeweils am Sonntag',
    },
    formTermOfContract: [
        { label: '24 Monate', value: '24_MONTH' },
        { label: '12 Monate', value: '12_MONTH' },
        { label: '6 Monate', value: '6_MONTH' },
        { label: '3 Monate', value: '3_MONTH' },
        { label: '1 Monate', value: '1_MONTH' },
        { label: '4 Wochen', value: '4_WEEK' },
        { label: '3 Wochen', value: '3_WEEK' },
        { label: '2 Wochen', value: '2_WEEK' },
        { label: '1 Woche', value: '1_WEEK' },
    ],
    formTax: [
        { label: '19 %', value: 19 },
        { label: '16 %', value: 16 },
        { label: '7 %', value: 7 },
        { label: '5 %', value: 5 },
        { label: '0 %', value: 0 },
    ],
    formPaymentRhythm: [
        { label: 'Jährlich', value: 'YEARLY' },
        { label: 'Quartal', value: 'QUARTER' },
        { label: 'Monatlich', value: 'MONTHLY' },
        { label: 'Wöchentlich', value: 'WEEKLY' },
    ],
    formCancelationPeriod: [
        { label: '3 Monate', value: '3_MONTH' },
        { label: '2 Monate', value: '2_MONTH' },
        { label: '1 Monate', value: '1_MONTH' },
        { label: '4 Wochen', value: '4_WEEK' },
        { label: '1 Woche', value: '1_WEEK' },
        { label: '1 Tag', value: '1_DAY' },
    ],

    auditAction: {
        APPOINMENT_CREATE: 'APPOINMENT_CREATE',
        APPOINMENT_EDIT: 'APPOINMENT_EDIT',
        APPOINMENT_DELETE: 'APPOINMENT_DELETE',

        APPOINMENT_ADDUSER: 'APPOINMENT_ADDUSER',
        APPOINMENT_CANCELUSER: 'APPOINMENT_CANCELUSER',
        APPOINMENT_DECLINE: 'APPOINMENT_DECLINE',
        APPOINMENT_ACCEPT: 'APPOINMENT_ACCEPT',
        APPOINMENT_CANCEL: 'APPOINMENT_CANCEL',

        ROLE_CREATE: 'ROLE_CREATE',
        ROLE_EDIT: 'ROLE_EDIT',
        ROLE_DELETE: 'ROLE_DELETE',

        TEMPLATE_CREATE: 'TEMPLATE_CREATE',
        TEMPLATE_EDIT: 'TEMPLATE_EDIT',
        TEMPLATE_DELETE: 'TEMPLATE_DELETE',

        PRODUCT_CREATE: 'PRODUCT_CREATE',
        PRODUCT_EDIT: 'PRODUCT_EDIT',
        PRODUCT_DELETE: 'PRODUCT_DELETE',

        COMPANY_EDIT: 'COMPANY_EDIT',
        LOGO_UPLOAD: 'LOGO_UPLOAD',
        LOGO_RESET: 'LOGO_RESET',
        PAYMENT_EDIT: 'PAYMENT_EDIT',

        MEMBERSHIP_SELF_CREATE: 'MEMBERSHIP_SELF_CREATE',
        MEMBERSHIP_SELF_DELETE: 'MEMBERSHIP_SELF_DELETE',

        MEMBERSHIP_CHANGEROLE: 'MEMBERSHIP_CHANGEROLE',
        MEMBERSHIP_DELETE: 'MEMBERSHIP_DELETE',
        MEMBERSHIP_ACCEPTED: 'MEMBERSHIP_ACCEPTED',
        MEMBERSHIP_REJECTED: 'MEMBERSHIP_REJECTED',

        CONTRACT_CHANGEPRODUCT: 'CONTRACT_CHANGEPRODUCT',
        CONTRACT_MEMBERSHIP_SETTINGS: 'CONTRACT_MEMBERSHIP_SETTINGS',
        CONTRACT_FLEXIBLETICKETS_CHARGE: 'CONTRACT_FLEXIBLETICKETS_CHARGE',
        CONTRACT_FLEXIBLETICKETS_CORRECTION:
            'CONTRACT_FLEXIBLETICKETS_CORRECTION',
        CONTRACT_MEMBERSHIP_END: 'CONTRACT_MEMBERSHIP_END',
    },
    auditActionLabel: {
        APPOINMENT_CREATE: 'Termin erstellt',
        APPOINMENT_EDIT: 'Termin bearbeitet',
        APPOINMENT_DELETE: 'Termin gelöscht',

        APPOINMENT_ADDUSER: 'Teilnehmer hinzugefügt',
        APPOINMENT_CANCELUSER: 'Teilnehmer storniert',
        APPOINMENT_DECLINE: 'Termin abgesagt',
        APPOINMENT_ACCEPT: 'Teilnahme',
        APPOINMENT_CANCEL: 'Storniert',

        ROLE_CREATE: 'Rolle erstellt',
        ROLE_EDIT: 'Rolle bearbeitet',
        ROLE_DELETE: 'Rolle gelöscht',

        TEMPLATE_CREATE: 'Terminvorlage erstellt',
        TEMPLATE_EDIT: 'Terminvorlage bearbeitet',
        TEMPLATE_DELETE: 'Terminvorlage gelöscht',

        PRODUCT_CREATE: 'Produkt erstellt',
        PRODUCT_EDIT: 'Produkt bearbeitet',
        PRODUCT_DELETE: 'Produkt gelöscht',

        COMPANY_EDIT: 'Firmenprofil bearbeitet',
        LOGO_UPLOAD: 'Logo hochgeladen',
        LOGO_RESET: 'Logo zurückgesetzt',
        PAYMENT_EDIT: 'Zahlungsdaten bearbeitet',

        MEMBERSHIP_SELF_CREATE: 'Mitgliedschaft angefragt',
        MEMBERSHIP_SELF_DELETE: 'Mitgliedschaft gelöscht',

        MEMBERSHIP_CHANGEROLE: 'Benutzerrolle geändert',
        MEMBERSHIP_DELETE: 'Mitglied gelöscht',
        MEMBERSHIP_ACCEPTED: 'Mitgliedschaft angenommen',
        MEMBERSHIP_REJECTED: 'Mitgliedschaft abgelehnt',

        CONTRACT_CHANGEPRODUCT: 'Vertrag geändert',
        CONTRACT_MEMBERSHIP_SETTINGS: 'Vertrag eingestellt',
        CONTRACT_FLEXIBLETICKETS_CHARGE: 'Flexible Tickets aufgeladen',
        CONTRACT_FLEXIBLETICKETS_CORRECTION: 'Flexible Tickets korrigiert',
        CONTRACT_MEMBERSHIP_END: 'Vertragsende',
    },
    productType: {
        MEMBERSHIP: 'Mitgliedschaft',
        FLEXIBLE_TICKET: 'Flexible Tickets',
    },
    productTypeENUM: {
        MEMBERSHIP: 'MEMBERSHIP',
        FLEXIBLE_TICKET: 'FLEXIBLE_TICKET',
    },
    appointmentStatus: {
        ACTIVE: 'ACTIVE',
        ARCHIVED: 'ARCHIVED',
    },
};
