import { useAuthContext } from '@/components/context/authContext';
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { getAuth, onIdTokenChanged } from 'firebase/auth';
import { useEffect, useMemo, useState } from 'react';
import firebaseApp from '../firebase/firebaseApp';
import Loading from '../ui/partials/Loading';

// const hasuraLink = bearerToken =>
//     new HttpLink({
//         uri: `${process.env.NEXT_PUBLIC_HASURA}`,
//         headers: bearerToken
//             ? {
//                   authorization: `Bearer ${bearerToken}`,
//               }
//             : {},
//     });

// const legacyLink = bearerToken =>
//     new HttpLink({
//         uri: `${process.env.NEXT_PUBLIC_GRAPHQL}`,
//         headers: {
//             authorization: bearerToken ? `Bearer ${bearerToken}` : '',
//         },
//     })

const CreateApolloClient = bearerToken => {
    // console.log('CreateApolloClient bearerToken:', bearerToken)

    return useMemo(() => {
        return new ApolloClient({
            // ssrMode: typeof window === 'undefined', // set to true for SSR
            ssrMode: false,
            // link: ApolloLink.split(
            //     // operation => operation.getContext().clientName === 'legacy', // Routes the query to the proper client
            //     // legacyLink(bearerToken),
            //     hasuraLink(bearerToken),
            // ),
            uri: `${process.env.NEXT_PUBLIC_HASURA}`,
            headers: bearerToken
                ? {
                      authorization: `Bearer ${bearerToken}`,
                  }
                : {},
            cache: new InMemoryCache({
                typePolicies: {
                    Query: {
                        fields: {
                            // appointments: offsetLimitPagination(),
                            appointments: offsetLimitPagination([
                                '$limit',
                                // '$offset',
                                '$where',
                                '$order_by',
                                // '$company_id',
                                // '$appointment',
                            ]),
                            // appointments: {
                            //     keyArgs: [
                            //         'limit',
                            //         'offset',
                            //         'where',
                            //         [
                            //             'appointment',
                            //             ['_eq'],
                            //             'company_id',
                            //             ['_lte'],
                            //         ],
                            //         'order_by',
                            //         ['appointment'],
                            //     ],
                            // },
                            audits: offsetLimitPagination([
                                '$company_id',
                                '$user_id',
                            ]),
                            bills: offsetLimitPagination(),
                        },
                    },
                },
            }),
        });
    }, [bearerToken]);
};

const GraphQl = ({ pageProps, children }) => {
    const [bearerToken, setBearerToken] = useState(undefined);

    // token update
    useEffect(() => {
        const instance = getAuth(firebaseApp);
        const authUnsubscriber = onIdTokenChanged(instance, async user => {
            if (user === null) {
                setBearerToken(null);
            } else {
                try {
                    let bearerToken = await user.getIdToken();
                    // console.log('new bearerToken', bearerToken);
                    setBearerToken(bearerToken);
                } catch (error) {
                    // Most probably a connection error. Handle appropriately.
                    console.log('bearerToken changed error', error);
                }
            }
        });

        // unsubscribe token listener on unmount
        return () => authUnsubscriber();
    }, []);

    // force refresh the token every 3 minutes
    useEffect(() => {
        // on first start
        const handle = setInterval(async () => {
            const user = getAuth(firebaseApp).currentUser;
            if (user) {
                try {
                    await user.getIdToken(); // refresh is under 5 minutes
                } catch (error) {
                    // Most probably a connection error. Handle appropriately.
                    console.log('user => getIdToken error', error);
                }
            }
        }, 3 * 60 * 1000); // 3 minutes

        // clean up setInterval
        return () => clearInterval(handle);
    }, []);

    // console.log('bearerToken', bearerToken)

    let client = CreateApolloClient(bearerToken);

    if (bearerToken === undefined) {
        return <Loading />;
    }

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { GraphQl };
