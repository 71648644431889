import { AuthProvider } from '@/components/context/authContext';
import { GraphQl } from '@/components/context/graphQl';
import Theme from '@/components/ui/Theme';
// import { init } from '@/components/util/sentry'
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-crop/dist/ReactCrop.css';
import './../sass/btn-burger.scss';
import './../sass/date-picker.css';
import './../sass/fancy.scss';

// swiper bundle styles
import 'swiper/css/bundle';

// swiper core styles
import 'swiper/css';

// modules styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// init()

const App = ({ Component, pageProps, err }) => {
    return (
        <AuthProvider>
            <ChakraProvider theme={Theme}>
                <GraphQl>
                    <Component {...pageProps} err={err} />
                </GraphQl>
            </ChakraProvider>
        </AuthProvider>
    );
};

export default App;
