import firebaseApp from '@/components/firebase/firebaseApp';
import { getAuth } from 'firebase/auth';

module.exports = async () => {
    const auth = getAuth(firebaseApp);
    let user = await auth.currentUser;

    if (!user) {
        throw new Error("Not authenticated. Make sure you're signed in!");
    }

    let bearerToken = await user.getIdToken();
    if (!bearerToken) {
        throw new Error('BearerToken is empty');
    }

    return bearerToken;
};
