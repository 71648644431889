export function isDev() {
    return process.env.NODE_ENV === 'development';
}

export function isTest() {
    return process.env.NODE_ENV === 'demo';
}

export function pluck(data, key) {
    if (typeof data === 'object') {
        data = Object.values(data);
    }
    return Array.from(new Set(data.map(obj => obj[key])));
}

export function dump(msg, data = null) {
    if (data === null) {
        console.log(msg);
    } else {
        console.log(msg, data);
    }
}
export function dumpError(msg, data = null) {
    if (data === null) {
        console.error(msg);
    } else {
        console.error(msg, data);
    }
}

export function toastSuccess(toast, msg, title = 'Erfolgreich') {
    toastBase(toast, 'success', title, msg);
}

export function toastError(toast, exception = null, msg, title = 'Fehler') {
    let desc = exception === null ? msg : exception.message;

    toastBase(toast, 'error', title, desc);
}

export function toastWarning(toast, msg, title = 'Warnung') {
    toastBase(toast, 'warning', title, msg);
}

function toastBase(toast, status, title, description) {
    toast({
        status,
        title,
        description,
        duration: 9000,
        isClosable: true,
    });
}

export async function makeApiRequest(
    method,
    path,
    data,
    isAuthenticated = true,
) {
    const getBearerTokenAction = require('./actions/getBearerTokenAction');
    const url = process.env.NEXT_PUBLIC_API_URL + path;

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    if (isAuthenticated) {
        headers['Authorization'] = 'Bearer ' + (await getBearerTokenAction());
    }
    dd('makeApiRequest', { method, url, headers, data });

    return await fetch(url, {
        method,
        headers,
        body: method === 'GET' ? null : JSON.stringify(data),
    })
        .then(async response => {
            let output;
            let isJson = response.headers
                .get('content-type')
                .includes('application/json');

            // no json > error
            if (!isJson) {
                output = await response.text();
                console.warn('makeApiRequest error NO JSON', output);
                throw new Error(`${response.status} - ${output}`);
            }
            output = await response.json();

            // wrong statuscode > error
            if (response.status !== 200) {
                let errorMessage = 'unkonwn error';
                if (output.msg && output.msg[0]) {
                    errorMessage = output.msg[0];
                }
                console.warn('makeApiRequest error WITH JSON output', output);
                console.warn(
                    'makeApiRequest error WITH JSON msg',
                    errorMessage,
                );
                throw new Error(`${response.status} - ${errorMessage}`);
            }

            return output;
        })
        .catch(error => {
            // Sentry.captureException(error, {
            //     extra: {
            //         url,
            //         data,
            //     },
            // })
            throw new Error(error.message);
        });
}

export async function makeRequest(
    path,
    data,
    isAuthenticated = true,
    method = 'POST',
) {
    const getBearerTokenAction = require('./actions/getBearerTokenAction');
    const getEnvironment = require('./environments/getEnvironment');
    let url = getEnvironment().functionUrl + path;

    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    if (isAuthenticated) {
        headers['Authorization'] = 'Bearer ' + (await getBearerTokenAction());
    }

    return await fetch(url, {
        method,
        headers,
        body: method === 'GET' ? null : JSON.stringify(data),
    })
        .then(async response => {
            let isJson = response.headers
                .get('content-type')
                .includes('application/json');
            if (isJson) {
                return response.json();
            }
            throw new Error(response.status + ' ' + (await response.text()));
        })
        .then(response => {
            if (!response.success) {
                throw new Error(response.error);
            }
            return response;
        })
        .catch(error => {
            // Sentry.captureException(error, {
            //     extra: {
            //         url,
            //         data,
            //     },
            // })
            throw new Error(error.message);
        });

    // return Promise.race([
    //   request,
    //   new Promise((_, reject) =>
    //     setTimeout(() => reject(new Error('Zeit abgelaufen (Timeout)')), 20000),
    //   ),
    // ]);
}

export async function makeDataRequest(path, data) {
    const getBearerTokenAction = require('./actions/getBearerTokenAction');
    const getEnvironment = require('./environments/getEnvironment');
    let url = getEnvironment().functionUrl + path;

    return await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            // 'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + (await getBearerTokenAction()),
        },
        body: data,
    })
        .then(async response => {
            let isJson = response.headers
                .get('content-type')
                .includes('application/json');

            if (isJson) {
                return response.json();
            }

            throw new Error(response.status + ' ' + (await response.text()));
        })
        .then(response => {
            if (!response.success) {
                throw new Error(response.error);
            }

            return response;
        })
        .catch(error => {
            // Sentry.captureException(error, {
            //   extra: {
            //     url,
            //     data,
            //   },
            // });
            throw new Error(error.message);
        });
}

/**
 * https://flaviocopes.com/javascript-sleep/
 *
 * usage:
 * await sleep(500).then(() => {
 *   //do stuff
 * })
 *
 * @param milliseconds
 * @returns {Promise<any>}
 */
export function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function getStorageUrlPublic(path) {
    if (!path) {
        return null;
    }

    if (path.includes('http')) {
        return path;
    }

    const getEnvironment = require('./environments/getEnvironment');

    return getEnvironment().storageUrl + path;
}

export function toDate(data) {
    if (!data) {
        return null;
    }

    if (typeof data === 'object') {
        if (typeof data.toDate === 'function') {
            return data.toDate();
        }

        if (
            data._seconds &&
            typeof data._seconds === 'number' &&
            data._nanoseconds &&
            typeof data._nanoseconds === 'number'
        ) {
            return new Date(data._seconds * 1000 + data._nanoseconds / 1e6);
        }
    }

    if (typeof data === 'string') {
        return new Date(data);
    }

    return data;
}

export function dd(msgOrData, data = null) {
    if (isDev()) {
        if (data === null) {
            console.log('#dd', msgOrData);
        } else {
            console.log('#dd ' + msgOrData, data);
        }
    }
}

// https://stackoverflow.com/questions/14810506/map-function-for-objects-instead-of-arrays
export const objectMap = (obj, fn) =>
    Object.fromEntries(
        Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]),
    );

export function isUserAdmin(user) {
    if (!user) {
        return false;
    }
    const getEnvironment = require('./environments/getEnvironment');
    return user.role === getEnvironment().userRole.ADMIN;
}

/**
 * https://day.js.org/docs/en/i18n/loading-into-nodejs
 * https://day.js.org/docs/en/plugin/localized-format
 * https://day.js.org/docs/en/parse/utc
 * https://day.js.org/docs/en/manipulate/utc-offset
 * @param {Date} date
 */
export function toTextDate(date, format = 'L', locale = 'en', isUTC = true) {
    var dayjs = require('dayjs');

    // format plugin
    var localizedFormat = require('dayjs/plugin/localizedFormat');
    dayjs.extend(localizedFormat);

    // utc plugin
    var utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);

    // locale german
    if (locale === 'de') {
        require('dayjs/locale/de');
    }

    // initialize
    let result = dayjs(date).locale(locale);

    // show as utc or local time
    if (isUTC) {
        result = result.utc(true);
    } else {
        // with this hack we can, summer and winter time correct show
        // Sommerzeit UTC+2
        // Normalzeit (Winterzeit) UTC+1
        //
        // If we have summer appointments for 18 o'clock. And than come winter the winter.
        // Than we want also show that the appointment with summer offset, that the date will
        // be also in 18 o'clock.
        result = result.utcOffset(dayjs().utcOffset());
    }

    return result.format(format);
}

export function toTextDateUTC(date, format = 'L', locale = 'en') {
    var dayjs = require('dayjs');

    // format plugin
    var localizedFormat = require('dayjs/plugin/localizedFormat');
    dayjs.extend(localizedFormat);

    // utc plugin
    var utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);

    // locale german
    if (locale === 'de') {
        require('dayjs/locale/de');
    }

    // initialize
    let result = dayjs.utc(date).locale(locale);

    // format
    return result.format(format);
}

/**
 * https://day.js.org/docs/en/manipulate/utc-offset
 * @param {Date} date
 */
export function toCurrentOffset(date) {
    var dayjs = require('dayjs');

    // initialize
    let result = dayjs(date);

    // remove date offset
    result = result.subtract(result.utcOffset(), 'minutes');

    // add current offset of today
    result = result.add(dayjs().utcOffset(), 'minutes');

    return result.toDate();
}

/**
 * https://day.js.org/docs/en/manipulate/utc-offset
 * @param {Date} date
 */
export function toDateOffset(date) {
    var dayjs = require('dayjs');

    // initialize
    let result = dayjs(date);

    // remove date offset
    result = result.subtract(dayjs().utcOffset(), 'minutes');

    // add current offset of today
    result = result.add(result.utcOffset(), 'minutes');

    return result.toDate();
}

export function hasProduct(membership) {
    if (!membership || !membership.product) {
        return false;
    }
    return true;
}

// /**
//  * https://day.js.org/docs/en/parse/utc
//  */
// export function dayjsutc() {
//     var dayjs = require('dayjs')

//     // format plugin
//     var localizedFormat = require('dayjs/plugin/localizedFormat')
//     dayjs.extend(localizedFormat)

//     // utc plugin
//     var utc = require('dayjs/plugin/utc')
//     dayjs.extend(utc)

//     return dayjs().utc(true)
// }

/**
 * https://day.js.org/docs/en/manipulate/utc
 * @param {Date} date
 */
export function toUTCString(date) {
    // console.log({
    //     1: date,
    //     // 2: date.toUTCSting(date),
    //     3: date.toGMTString(),
    //     4: date.toLocaleString(),
    //     5: date.toISOString(),
    //     6: date.toLocaleDateString(),
    //     7: date.toUTCString(),
    // })

    // return date.toISOString()
    if (date === null) {
        return null;
    }

    var dayjs = require('dayjs');

    // utc plugin
    var utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);

    let convertedString = dayjs(date).utc(true).format();

    return convertedString;
}

export function toTimeString(date) {
    if (date === null) {
        return null;
    }

    var dayjs = require('dayjs');
    return dayjs(date).format('HH:mm');
}

export function toInt(value) {
    if (value) {
        return parseInt(value);
    } else {
        return 0;
    }
}

export function getUTCDate() {
    const dayjs = require('dayjs');

    // utc plugin
    const utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);

    return dayjs().utc(true).toDate();
}
